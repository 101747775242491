import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'


// Import Layout 
import Default from "../layouts/Default.vue";

// import lGuest from '../layouts/Guest.vue'
import Panel from '../layouts/Panel.vue'

import LayoutAuth from '../layouts/Auth.vue'


Vue.use(VueRouter)

const routes = [

  // View: Invoice
  {
    path: '/pagos',
    name: 'invoice',
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import("../views/invoice/InvoicesView.vue")
  },


  // * ---------------------------------------------------------------------------------- Public
  {
    path: '/', name: 'Login', meta: { layout: LayoutAuth }, component: () => import('../views/Auth/Login.vue')
    // component: () => import('../views/public/Login.vue')
  }, {
    path: '/recuperar-password',
    name: 'Recovery',
    meta: { layout: LayoutAuth },
    component: () => import('../views/Auth/Recovery.vue')
  },


  // * ---------------------------------------------------------------------------------- Modules: Access

  {
    path: '/acceso/:uid',
    name: 'ScannerEvent',
    meta: { auth: true, scan: true, layout: Panel },
    component: () => import("../modules/access/Scanner.vue")
  },

  // * ---------------------------------------------------------------------------------- modules: events


  {
    path: '/eventos/historial',
    name: 'EventsHistory',
    meta: { auth: true, owner: true, seller: true, layout: Panel },
    component: () => import("../modules/events/History.vue")
  },


  // * ---------------------------------------------------------------------------------- Modules

  // Events: Home
  {
    path: '/mis-eventos',
    name: 'MyEvents',
    meta: { auth: true, seller: true, owner: true, scan: true, layout: Panel },
    component: () => import("../modules/events/Home.vue")
  }, // Events: Detail
  {
    path: '/evento/:uid', // Shared
    name: 'MyEvent',
    meta: { auth: true, seller: true, owner: true, scan: true, layout: Panel },
    component: () => import("../modules/events/Detail.vue")
  },

  // Tranpsort 
  {
    path: '/modulo/transporte',
    name: 'transporte',
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import('../modules/transport/List.vue')
  },


  // Module: VIP
  {
    path: '/asistentes/especiales', // Shared
    name: 'attendees-specials',
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import('../modules/vip/Home.vue')
  },


  // [owner | staff]
  {
    path: "/staff",
    name: "Staff",
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import("../views/owner/Staff.vue")
  },

  // [public | CreateIdCard ]
  {
    path: '/crear-id', name: 'create-id', meta: { layout: Default }, component: () => import('../views/CreateId.vue')
  },


  {
    path: '/scan/:uid', // Scan
    name: 'Scan', meta: { auth: true, scan: true, layout: Panel }, component: () => import('../views/shared/Scan.vue')
  }, // v2.3

  // Attendees
  {
    path: "/blacklist",
    name: "BlackList",
    meta: { auth: true, owner: true, layout: Panel },
    component: () => import('../views/Attendees/BlackList.vue')
  },


  // * ---------------------------------------------------------------------------------- Admin
  {
    path: "/register", name: "Register", meta: { layout: Default }, component: () => import('../views/Register.vue')
  },]

const router = new VueRouter({
  mode: 'history', base: process.env.BASE_URL, routes
})

router.beforeEach((to, from, next) => {


  auth.onAuthStateChanged(userAuth => {

    if (userAuth) {
      auth.currentUser.getIdTokenResult()
        .then(function ({
          claims
        }) {

          if (claims.admin) {
            if (to.matched.some(record => record.meta.admin)) {
              next()
            } else {
              console.debug("admin")
              // return next({ name: 'Admin' })
            }
          } else if (claims.owner) {
            if (to.matched.some(record => record.meta.owner)) {
              next()
            } else {
              return next({ name: 'MyEvents' })
            }
          } else if (claims.scan) {
            if (to.matched.some(record => record.meta.scan)) {
              next()
            } else {
              return next({ name: 'MyEvents' })
            }
          } else {
            if (to.matched.some(record => record.meta.seller)) {
              next()
            } else {
              return next({ name: 'MyEvents' })
            }
          }

        })
    } else {
      if (to.matched.some(record => record.meta.auth)) {
        next({
          name: 'Login', query: {
            redirect: to.fullPath
          }
        })
      } else {
        next()
      }
    }
  })
  next()
})

export default router