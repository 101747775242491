<template>
  <v-app id="relevent-layout">
    <!-- navigation -->
    <navigation-drawer v-model="drawer"/>

    <!-- [main] -->
    <v-main>

      <panel-warning :disabled="company.disabled" :warning="company.warning"/> <!-- #waring -->

      <!-- Alert -->
      <v-container fluid v-if="company.warning && user.owner">
        <v-card flat>
          <v-list-item class="alert-relevent" two-line>
            <!-- alert-icon -->
            <v-list-item-icon>
              <v-icon x-large color="red">mdi mdi-alert-box</v-icon>
            </v-list-item-icon>
            <!-- alert-content -->
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">Pendiente de pago</v-list-item-title>
              <v-list-item-subtitle>¡Atención! Su suscripción ha vencido y está próxima a caducar. Regularícela para
                evitar la cancelación del servicio.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-container>

      <slot/>
    </v-main>


    <panel-loading v-model="loading"/> <!-- #loading -->


    <!-- Update -->
    <v-overlay :value="updateExists" opacity="0.8">
      <v-card max-width="300">
        <v-card color="#1A237E" flat>
          <v-card-text class="pa-12">
            <v-img src="@/assets/relevent.png"/>
          </v-card-text>
        </v-card>
        <v-card-text class="text-center">
          <h1 class="text-h6 | px-9 | white--text" style="line-height: 1.2">
            ¡Actualización disponible!
          </h1>
          <p class="mt-6 mb-6">
            Hay una nueva actualización disponible para Aldus con nuevas
            característica.
          </p>
          <v-btn color="primary" @click="refreshApp" large block depressed>
            Actualiza Aquí
          </v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- dialog: scan -->
    <dialog-scan v-model="dialog"/>
  </v-app>
</template>

<script>
import update from "@/mixins/update";
import {mapActions, mapState} from "vuex";
import NavigationDrawer from "./components/Drawer.vue";
// componets
import DialogScan from "../components/DialogScan.vue";

// components: v2
import PanelLoading from './components/Loading.vue';
import PanelWarning from "./components/Warning.vue"

export default {
  mixins: [update],
  components: {PanelLoading, PanelWarning, NavigationDrawer, DialogScan},

  data: () => ({
    dialog: false,
    drawer: null,
    loading: false,
  }),

  computed: {
    ...mapState(["mode"]),
    ...mapState("auth", ["user", "company"]),
  },

  methods: {
    ...mapActions("auth", ["getCompany"]),

    scanMode() {
      // verify if scan
      const role = this.user.role;

      if (role === "scan" && this.mode === null) {
        this.dialog = true;
      }
    },
  },

  async created() {
    this.loading = true;
    await this.getCompany();
    this.loading = false;
    this.scanMode();
  },

  mounted() {
    this.$root.$on("drawer", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
